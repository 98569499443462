/* app download */
.dwn__wrapper{
    width: 1200px;
    margin: 3rem auto;
    padding: 7rem 3rem 4rem;
    }
.dwn__wrapper-section{
    display: flex;
    flex-direction: row;
    
}
.dwn__wrapper-img{
    display: block;
    width: 50%;
}
.dwn__wrapper-img img{
    width: 100%;
    border-radius: 10px;
}
.dwn__wrapper-text{
    display: flex;
    flex-direction: column;
    width: 47%;
    padding-left: 2em;
    text-align: center;
}
.wt-head{
    margin-top: 1rem;
}
.wt-head h1{
    font-size: 2.25rem;
    line-height: 3rem;
    color: #2b065e;
}
.wt-dwn-pic{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.wt-dwn-pic img{
    margin-right: 10px;
}
.wt-txt{
    margin-bottom: 1rem;
}
.wt-txt p{
    margin-bottom: 0.5rem;
    color: #54606A;
}
.wt-qrcode{
    margin-bottom: 2rem;
}
.wt-qrcode img{
    width: 150px;
}
.dwn__wrapper_right_padding{
padding-right: 9rem;
}
.question-app {
    background-color: #fff;
    width: 950px;
    min-height: 60vh;
    /* border-radius: 15px; */
    padding: 20px;
    margin: 0 auto;
    margin-top: 85px;
    margin-bottom: 85px;
    box-shadow: 0 3px 5px rgb(160 159 159 / 50%);
  }
  
  .score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
  }
  
  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    position: relative;
  }
  
  .question-count {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .question-count span {
    font-size: 22px;
    color: #5f5e61;
  }
  
  .question-text {
    margin-bottom: 12px;
    font-size: 22px;
    text-align: center;
    font-weight: bold;
  }
  
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    
    position: relative;
    padding: 10px;
    font-size: 25px;
    text-align: center;
    /* align-items: center; */
    /* margin-left: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
  .question-button {
    width: 80%;
    margin: 10px 0;
    background: #fff;
    border: 1px solid #dbdbdb;
border-radius: 3px;
font-family: Noto Sans,sans-serif;
font-size: 17.5px;
padding: 24px 25px;
text-align: left;
cursor: pointer;
outline: none;
    text-align: center;
  }
 
  .correct {
    background-color: #2f922f;
  }
  
  .incorrect {
    background-color: #ff3333;
  }

  
  .question-button:focus {
    outline: none;
  }
  
  .question-button svg {
    margin-right: 5px;
  }

.question-previous {
    padding: 15px 21px;
    border-radius: 5px;
    color: #fff;
    background-color: rgb(148 145 145);
}

.question-previous:disabled {
    background-color: rgb(255, 255, 255);
}
.question-submit {
    padding: 15px 21px;
    border-radius: 5px;
    color: #fff;
    background-color: rgb(63, 77, 206);
}
.question-submit {
    background-color: rgb(42, 56, 179);
}
.question-submit:disabled{
    background-color: #9e9fa0;
}
.email-input-question {
    width: 80%;
    height: 60px;
    padding: .475rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;

}
.selected {
   
        width: 100%;
        font-size: 16px;
        color: #ffffff;
        background-color: #43d843;
        border-radius: 15px;
        /* display: flex; */
        padding: 5px;
        justify-content: flex-start;
        /* align-items: center; */
        border: 5px solid #234668;
        cursor: pointer;
        margin-bottom: 10px;
        text-align: center;
      
}

.te__card {
    position: relative;
    display: flex;
    width: 450px;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    /* border: 1px solid #d2d2dc; */
    border-radius: 11px;
    -webkit-box-shadow: 0px 0px 5px 0px rgb(249, 249, 250);
    -moz-box-shadow: 0px 0px 5px 0px rgba(212, 182, 212, 1);
    box-shadow: 0px 0px 5px 0px rgb(161, 163, 164)
}

.te__card .te__card-body {
    padding: 1rem 1rem
}

.te__card-body {
    flex: 1 1 auto;
    padding: 1.25rem
}
.te__card-title{
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}
.te__card-title img{
    width: 69px;
}
.te__card p {
    font-size: 0.875rem;
    margin-bottom: .5rem;
    line-height: 1.5rem
}

.te__card h4 {
    line-height: .2 !important
}

.te__card .profile {
    margin-top: 16px;
    margin-left: 11px
}

.te__card .profile-pic {
    width: 58px
}

.te__card .cust-name {
    font-size: 18px
}

.te__card .cust-profession {
    font-size: 10px
}

.te__items {
    width: 90%;
    margin: 0px auto;
    margin-top: 67px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.te__bg-ds{
    background-color: #89e2e2;
    padding: 26px 0 87px;
}
.slick-slide {
    margin: 10px
}
.tes__plan-container {
    width: 90%;
    margin: 60px auto;
}
.plan-content_second{
    min-height: 175px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-top: 10px;
}
.plan-content_second p {
    font-size: 14px;
    color: #767683;
    text-align: left;
    margin-bottom: 5px;
    padding-left: 10px;
    
}
.ser-heading {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.5;
    margin-top: 27px;
}
.ch__help-container{
    padding: 0 25px;
    margin: 60px auto;
    display: flex;
    flex-direction: row;
    width: 90%;
}
 .left-section{
    padding: 10px;
    width: 50%;
}

.left-section .help-points{
    padding: 40px;
}
.left-section .help-points p{
    display: block;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 0;
    border-bottom: 1px solid #e0e0e0;
}
.right-section {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.right-section img{
    width: 70%;
    border-radius: 20px;
    
}
.selected-product-cl{
    background-color: #dedddd;
    border: 2px solid #2A265F;
}
.ques__buttons{
    width: 80%;
    display: flex;
    margin: 60px auto;
    justify-content: space-between;
}
.save-cost{
    position: absolute;
    right: 0;
    top: 0;
    background: #ce1818;
    padding: 7px;
    border-bottom-left-radius: 5px;
    color: #fff;
    font-size: 0.8em;
    font-weight: 600;
}
.seld-ques{
    background-color: #58d5c9;
}
.seld-ques:hover{
    background-color: #58d5c9!important;
}
.plan-def-toog{
    padding-top: 10px;
    display: none;
}
.tick-mark {
    position: absolute;
    /* left: 0; */
    top: 1px;
    /* display: inline-block; */
    height: 30px;
    background: white;
    color: #000;
    /* padding: 10px; */
    z-index: 1000000000;
}
.expand-icon-product {
    display: flex;
    padding: 10px;
    cursor: pointer;
}
.dep__banner{
    /* background: url(../images/services/depressionv1.jpg) no-repeat  50% 50% ; */
    background-size: cover;
    cursor: pointer;
    
}
.stress__banner{
    /* background: url(../images/services/stressv1.jpg) no-repeat  50% 50%; */
    background-size: cover;
    cursor: pointer;
    
}
.relation__banner{
    /* background: url(../images/services/relationshipv1.jpg) no-repeat  50% 50%; */
    background-size: cover;
    cursor: pointer;
    
}
.online__banner{
    /* background: url(../images/services/onlinetherapyv1.jpg) no-repeat  50% 50%; */
    background-size: cover;
    cursor: pointer;
    
}
.chat__banner{
    /* background: url(../images/services/chatv1.jpg) no-repeat  50% 50%; */
    background-size: cover;
    cursor: pointer;
    
}
.gen__segment-container {
    width: 85%;
    margin: 40px auto 50px;
    box-shadow: 0 4px 18px 3px #d6d3ea;
    border-radius: 9px;
    background-color: #fff;
}
.exp__help-container {
    padding: 0 25px;
    margin: 0 auto ;
    display: flex;
    width: 90%;
    flex-direction: row;
}
.pcr__card-container{
    width: 80%;
    margin: 0 auto;
}
.product__cards{
    display: flex;
  flex-wrap: wrap;
}
.p__card{
    margin: 20px;
  padding: 20px;
  width: 29%;
  min-height: 100px;
  display: grid;
  grid-template-rows: 30px 30px 30px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}
.p__card:hover{
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
}
.pcr__title{
    grid-row: 2/3;
    font-size: 1.4em;
    font-weight: 600;
  color: #ffffff;
  text-align: center;
}
.pcr1 {
    background: radial-gradient(#08b5c3, #297ba9);
  }
  
  .pcr2 {
    background: radial-gradient(#c56477, #921e3b);
  }
  
  .pcr3 {
    background: radial-gradient(#5783bb, #3e2880);
  }
  
  .pcr4 {
    background: radial-gradient(#3eb98d, #108075);
  }
  
  .pcr5 {
    background: radial-gradient(#ca45a6, #4d3171);
  }

  .pdm-top{
      padding-top: 40px;
  }
  .res__box p {
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.9em;
}

.res__box {
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px rgba(0, 0, 0, 0.4);
    padding: 30px;
    margin: 20px;  
}
.res__box h2{
    font-size: 1.3em;
}
.resolves__container {
    margin-bottom: 11rem;
}
.a__therapists_section{
    display: flex;
    flex-direction: column;
}
.la__img-circle{
    width: 240px;
    height: 240px;
    margin: 0 auto;
}
.la__img-circle img{
    width: 100%;
    border-radius: 50% !important;
}
p.la__role {
    color: #b3b3b3 !important;
    word-wrap: break-word;
    position: relative;
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
}
.la__name{
    font-size: 1.5rem;
    margin-top: 12px;
    margin-bottom: 0;
    text-transform: none;
    font-weight: 700;
    color: #d8a8ab !important;
    word-wrap: break-word;
    position: relative;
    text-align: center;
}
.la__bio{
    color: #5a5959;
    font-size: .9em;
    margin-top: 23px;
    margin-bottom: 0;
    font-weight: 300;
    font-style: normal;
    word-wrap: break-word;
    position: relative;
    text-align: center;
}
.area-expertise {
    margin-top: 23px;
}
.area-expertise h6{
    text-decoration: underline;
    text-align: center;
}
.area-expertise li{
    font-size:.95em ;
    color: #5a5959;
}
.spc-top{
margin-top: 40px;
}
@media (max-width: 450px) {
    .res__box {
        height: 200px;
    }
    .resolves__container {
        margin-bottom: 0!important;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
    .res__box {
        text-align: center;
        height: 180px;
    }
}

.cyan {
    border-top: 3px solid var(--cyan);
}
.red {
    border-top: 3px solid var(--red);
}
.blue {
    border-top: 3px solid var(--blue);
}
.orange {
    border-top: 3px solid var(--orange);
}
.green {
    border-top: 3px solid var(--green);
}
.yellow {
    border-top: 3px solid var(--yellow);
}




@media (min-width: 950px) {
    .res__row1-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .res__row2-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .res__box-down {
        position: relative;
        top: 150px;
    }
    .res__box {
        width: 26%;
     
    }
   
    
}
    @media screen and (max-width: 900px){
    .dna-wrapper {
        margin: 2rem auto;
        padding: 9rem 3rem 4rem;
    }
    .dwn__wrapper{
        width: 700px !important;
    }
    .dwn__wrapper-section{
        flex-direction: column !important;
    }
    .dwn__wrapper-img{
        width: 100% !important;
    }
    .dwn__wrapper-text{
           
        width: 100% !important;
    }
    .te__card{
        width: 300px;
    }
    .question-app{
        width: 700px;
    }
    }

  @media screen and (max-width: 500px){
    .ser-heading {
        font-size: 23px;
    }
    .dwn__wrapper_right_padding{
        padding-right: 2rem !important;
        }
        .dwn__wrapper{
            width: 360px !important;
            padding:5rem 0 0 !important;
        }
        .dwn__wrapper-text{
           
            width: 100% !important;
        }
        .question-app{
            width: 370px;
        }
        .te__items{
            flex-direction: column;
        }
        .te__card{
            width: 100%;
            margin-top: 10px;
        }
        .plan-content_second {
            min-height: fit-content!important;
        }
        .question-button {
            width: 90%;
            padding: 15px 25px;
            font-size: 14px;
        }
        .question-text {
            margin-bottom: 0px;
            font-size: 18px;
        }
        .question-count {
            margin-bottom: 10px;
        }
        .dep__banner{
            /* background: url(../images/services/depression-mobile.jpg) no-repeat 50% 50% ; */
            background-size: contain;
            margin-top: 1.2em;
        }
        .stress__banner{
            /* background: url(../images/services/stress-mobile.jpg) no-repeat 50% 50% ; */
            background-size: contain;
            margin-top: 1.2em;
        }
        .relation__banner{
            /* background: url(../images/services/relationship-mobile.jpg) no-repeat 50% 50% ; */
            background-size: contain;
            margin-top: 1.2em;
        }
        .online__banner{
            /* background: url(../images/services/onlinetherapy-mobile.jpg) no-repeat 50% 50% ; */
            background-size: contain;
            margin-top: 1.2em;
        }
        .chat__banner{
            /* background: url(../images/services/chat-mobile.jpg) no-repeat 50% 50% ; */
            background-size: contain;
            margin-top: 1.2em;
        }
        .ch__help-container{
            flex-direction: column;
            width: 100%;
        }
        .exp__help-container{
            flex-direction: column;
            width: 100%;
        }
        .left-section{
            width: 90%;
        }
        .right-section{
            width: 90%;
        }
        .pcr__card-container{
            width: 100%;
        }
        .p__card{
            width: 90%!important;
            margin: 10px 20px;
            min-height: 70px;
            grid-template-rows: 2px 25px 2px;
        }
        .pcr__title{
            font-weight: 500;
        }
        .left-section .help-points{
            padding: 0!important;
        }
        .right-section img{
            width: 90%;
        }
        .care-h-menu-link .btn{
            padding: 0!important;
        }
        .save-cost{
            padding: 5px;
        }
        .program-description {
            padding: 8px!important;
        }
    }


.typeform-welcome-screen {
    position: absolute;
    bottom: 50%;
    left: 50%;
    border: 1px solid #fff;
}
.typeform-welcome-text {
    text-align: center;
    transform: translateX(-50%) translateY(-50%);
    
}
.typeform-button{
    margin-top: 15px;
    padding: 10px;
    color: #fff;
    background-color: #0445AF;
    width: 80px;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    right: 17%;
    border-radius: 5px;
}
.typeform-button:hover{
    background-color: rgb(79, 36, 197);
}
.typeform-app{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.typeform_Progress{ 
        position: relative;
        transition: width .4s ease;
        margin-bottom: 1em;
        background: rgb(181, 181, 181);
        border-radius: 0;
        width: 100%;
        height: 2em;
}
.typeform_progress_bar{
        min-height: 28px;
        background-color: #91AEDC;
}
.typeform-question-bar{
   top: 20;
    position: fixed;
    left: 30%;
}
.typeform_count_bar{
    position: absolute;
    right: 5px;
    top: 0;
    font-weight: normal;
    color: #fff;
    height: 100%;
    /* font-family: @sans; */
    font-size:1.25em;
    margin: auto .5em;
    letter-spacing:.025em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.typeform-question-text{
    position: relative;
    left: 18%;
    margin-bottom: 20px;
    font-size: 20px;
    color: black;
    font-weight: bold;
    max-width: 70%;

}
.typeform-answer-section{
    position: relative;
    left: 22%;
    width: 20%!important;
    max-width: 30%;
    height: 2%;
}
.typeform-question-button   {
    width: 135%;
    margin: 4px 0;
    background: #fff;
    border: 1.5px solid #91AEDC;
    border-radius: 3px;
    font-family: Noto Sans,sans-serif;
    font-size: 17.5px;
    padding: 8px 39px 8px 18px;
    text-align: left;
    cursor: pointer;
    outline: none;
    text-align: center;
    background-color: #E5ECF7;
    color: #0445AF;
    max-width: 140%;

}
.typeform-inline-text{
    position: relative;
    left: 3%;
    right: 40%;
}
.typeform-question-count{
    position: relative;
    margin-bottom: 150px;
}

.typeform-ques__buttons {
    
    position: fixed;
     bottom: 30px;
     right: 60px;

}

.typeform-ok-button{
    position: relative;
    left:22%;
    font-weight: bolder;
    padding: 10px 25px;
    border-radius: 5px;
    margin-top: 10px;
    color: #fff;
    background-color: #0445AF;

}
.typeform-question-previous {
    padding: 10px 16px;
    border-radius: 5px;
    margin: 1px;
    padding: 10px 12px;
    font-size:15px;
    color: #fff;
    background-color: rgb(148 145 145);
}
.typeform-assesment-screen{
    width:100%;
    height: 100%;
}
.typeform-submit-section{
    margin-left: 222px;
    width: 600px;
}
.typeform-back-arrow{
    margin-bottom: 10px;
    color: #0445AF;
    }
    .typeform-back-arrow:hover{
        color: rgb(25, 226, 68);
    }
.typeform-submit-section h3{
    max-width: 40%;
    text-align: center;
}
.typeform-question-submit {
    position: relative;
    font-weight: bold;
    left: 13%;
    padding: 10px 25px;
    border-radius: 5px;
    margin-top: 10px;
    color: #fff;
    background-color: #0445AF;
}
.typeform-check-icon{
    position: relative;
    left:15%
}
.show_up{
    margin-top: -45px;
}
.show_case{
    padding: 5px;
    color:#215352;
    font-weight: bold;
    background-color: #fff;
    border-radius: 2px;
    text-align: center;
    margin: 10px auto;
}
.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 2rem!important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}
.typeform-text-input{
    border:none;
    border-bottom: 1px solid blue;
    width:150%;
    font-size: 20px;
    color: cornflowerblue;
    position: relative;
    left: 0%;
}
.typeform-textarea{
    border: none ;
    border-bottom: 1px solid blue;
    width:150%;
    font-size: 20px;
    color: cornflowerblue;
    position: relative;
    left: 0%;
    padding-bottom: -30px;
    padding-top: 0px;
}
textarea.typeform-textarea:focus{
    outline: none;
}
input.typeform-text-input:focus{
   outline: none;
}
.input[type="checkbox"] {
    display: none
}


input[type="file"] {
    display: none;
}
.typeform-file-input{
    position: relative;
    left:50%;
    width: 450px;
    height: 200px;
    background-color: #B1DE00;
    border: 1px dotted blue;
    padding: 70px 50px;
    /* background:  url(../images/base/uploadimage.png) no-repeat 50%, 40%; */
    background-size: 50px 50px;
}
.typeform-file-span {
    position: relative;
    top: 100%;
    left: 30%;
    font-size: 12px;
}
.typeform-file-span1 {
    position: absolute;
    top:70% ;
    text-align: center;
    left: 30%;
}
.typeform-image{
    flex: 1;
    flex-wrap:wrap;
    flex-direction: row;
}
.typeform-image-input{
    flex-basis: 50%;
  margin: 20px;
   border: 1.3px solid #91AEDC;
   background: #E5ECF7;
   padding: 10px 0px 10px 60px;
}
.typeform-select-input{
    max-height: 500px;
}


.css-1s2u09g-control {
    width: 200%;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    /* border-color: hsl(0, 0%, 80%); */
    /* border-radius: 4px; */
    /* border-style: solid; */
    /* border-width: 1px; */
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    /* min-height: 38px; */
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    /* box-sizing: border-box; */
    font-size: 20px;
    margin-bottom: 160px;
    border: none!important;
    border-bottom: 2px solid #91AEDC!important;

}

.css-1pahdxg-control{
width: 200%;
    }
    .css-26l3qy-menu{
        width: 200% !important;
        margin: 2px;
    }
  
    .container{
         display: flex;
         flex-direction: column;
         /* width: 2px; 
         height: 20px;
         margin: 15px auto;  */
        }
  
    
div label input {margin-right: 100px;}

.cat{
  color: #0445AF;
  background-color:#E5ECF7 ;
  border-radius: 4px;
  border: 1px solid #104068;
  overflow: hidden;
  max-width: 300px;
  margin: 2px;
  text-align: center;
  margin-left: -10px;
}
.check-icon{
   position: relative;
   left:60%
}
.cat label {
  /* float: left; */
   line-height: 3.0em;
   width: 8.0em;
   height: 3.0em;
}
.cat label span {
  text-align: center;
  padding: 3px 0;
  display: block;
}

.cat label input {
  position: absolute;
  display: none;
  color: #0445AF;
}
.cat label input + span{color: #0445AF;}
.cat input:checked + span {
    color: #0445AF;
}
.action input:checked + span{background-color: #E5ECF7;}
      
.react-datepicker-wrapper {
    width: 200px!important;
        display: inline-block;
        padding: 0;
        border: 0;
        border: none;
        outline: none!important;
        border: none !important;
        border-color: #fff;
        border-bottom: 2px solid black;
    }
    .react-datepicker__input-container{
        width: 200px!important;
        border: none;
        outline: none!important;
        border: none !important;
        border-color: #fff;
        border-bottom: 2px solid black;
    }
    .react-datepicker-wrapper:focus{
        outline: none!important;
    }  
.typeform-scale-input{
    width: 250%!important;
    position: relative;
  margin-bottom: 20px;
}
.btn-scale {
    min-width: 55px;
    width: 6%;
    height: 50px;
    margin: 2px;
    text-align: center;
    font-weight: bold;
    color: #0445AF;
    font-family: 'Lato', sans-serif;
  }
  

.btn-scale-asc-1{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}
.btn-scale-asc-2{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-3{
    border: 2px solid #91AEDC!important;
    background-color:#E5ECF7!important ;
}.btn-scale-asc-4{
    border: 2px solid #91AEDC!important;
    background-color:#E5ECF7!important ;
}.btn-scale-asc-5{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-6{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-7{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-8{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-9{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-10{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}
  /* .btn-scale-asc-1 {
    background-color: #33FF00!important;
  }
  
  .btn-scale-asc-1:hover {
    background-color: #2CDE00!important;
  }
  
  .btn-scale-asc-2{
    background-color: #66FF00!important;
  }
  
  .btn-scale-asc-2:hover{
    background-color: #59DE00!important;
  }
  
  .btn-scale-asc-3
  {
    background-color: #99FF00!important;
  }
  
  .btn-scale-asc-3:hover {
    background-color: #85DE00!important;
  }
  
  .btn-scale-asc-4 {
    background-color: #CCFF00!important;
  }
  
  .btn-scale-asc-4:hover {
    background-color: #B1DE00!important;
  }
  
  .btn-scale-asc-5{
    background-color: #FFFF00!important;
  }
  
  .btn-scale-asc-5:hover {
    background-color: #DEDE00!important;
  }
  
  .btn-scale-asc-6{
    background-color: #FFCC00!important;
  }
  
  .btn-scale-asc-6:hover {
    background-color: #DEB100!important;
  }
  
  .btn-scale-asc-7 {
    background-color: #FF9900!important;
  }
  
  .btn-scale-asc-7:hover {
    background-color: #DE8500!important;
  }
  
  .btn-scale-asc-8 {
    background-color: #FF6600!important;
  }
  
  .btn-scale-asc-8:hover {
    background-color: #DE5900!important;
  }
  
  .btn-scale-asc-9 {
    background-color: #FF3300!important;
  }
  
  .btn-scale-asc-9:hover {
    background-color: #DE2C00!important;
  }
  
  .btn-scale-asc-10{
    background-color: #FF0000!important;
  }
  
  .btn-scale-asc-10:hover{
    background-color: #DE0000!important;
  } */
  @import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");
  /*   color variables */
  /*   border radius */
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: Oxygen, sans-serif;
    /* margin: 2rem; */
  }
  .asses_header{
      position: relative;
      text-align: center;
      margin-top: 90px;
  }
  .asses_header h1{
      padding: 10px;
      font-weight: bold;
      font-size: 45px;
  }
  .asses_innertext{
      position:relative;
      left: 20%;
      max-width: 56%;
      font-size: 18px;
      margin-bottom: 45px;
      
  }
  .asses_card__body{
      position: relative;
  }
  .asses_wrapper {
    position: relative;
    left: 15%;
    max-width: 64%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 12rem));
    grid-gap: 18rem;
    grid-gap: 3rem 15rem;
    gap: 1rem 10.5rem;
    justify-content: space-between;
  }
  .asses_card {
     position: relative;
    overflow: hidden;
    padding: 0;
    box-shadow: 0 2px 20px #e1e5ee;
    border-radius: 1rem;
    width: 180%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
   
  }
  .asses_card__title_hide{
      text-align: center;
    opacity: 0;
    position: absolute;
    top: 68%;
    left: 35%;
    opacity: 0;
    font-size: 20px;
    color: #ffffff;
  }

  .asses_card__title_hide.MuiSvgIcon-root {
    fill: currentColor;
    width: 0.5em!important;
    height: 1em;
    display: inline-block;
    font-size: 0.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}
  .asses_card:hover .asses_card__title_hide{
      opacity: 1;
  }
  .asses_card__image {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    opacity: .80;
  }
  .asses_card__title {
    position: absolute;
    color: #ffffff;
    top: 50%;
    left: 30%;
    font-size: 30px;
    font-weight: bold;
    z-index: 1000;

  }
  .asses_card__description {
    padding: 0 1rem;
  }
  .asses_card__btn {
    padding: 1rem;
    font-family: inherit;
    font-weight: bold;
    font-size: 1rem;
    margin: 1rem;
    border: 2px solid #d50000;
    background: transparent;
    color: #d50000;
    border-radius: 0.2rem;
    transition: background 200ms ease-in, color 200ms ease-in;
  }
  .asses_card:hover {
    transform: scale(1.02);
  }
  .asses_card:hover .asses_card__btn {
    background: #d50000;
    color: white;
  }
  .asses_mid{
      width:auto;
      height:520px;
      background-color: rgb(178, 210, 218);
      margin-top: 50px;
      display: flex;
      flex-direction: row;
  }
  .asses_mid_img{
        border-radius: 160px 0 0 20px;
        position: relative;
        left:32%;
        background-color: #98b5bb;
       
  }
  .asses_section_header{
      text-align: center;
      margin-bottom: 30px;
  }
  .asses_therpy_signup{
      margin-top: 350px;
      width:290px;
      height:50px;
      padding:10px;
      position: relative;
      left:10%;;
      border-radius: 10px;
      font-weight: bolder;
      background: #6ec2d3;
      color: 'white';
  }
  .welcome_asses {
    background: linear-gradient(rgb(237,203,195), rgba(238, 238, 226, 0.95))!important;
    width: auto;
    height: 460px;
    margin-top: 50px;
    display: flex;
    margin-top: 60px;
  }
  .welcome_note_asses {
    width:500px;
    position: relative;
    left: 20%;
    top: 20%;
  }
  .start_asses{
      height: 40px;
      min-width: 120px;
      padding: 10px;
      position: relative;
      top: 70%;
      left: -4%;
      background-color: rgb(40, 93, 207);
      color: #fff;
      font-weight: bold;
      border-radius: 10px;
  }
  .welcome_asses img{
      position: relative;
      left: 10%;
  }
  .gad_asses{
    background: linear-gradient(rgb(207,205,204), rgba(207,205,204,0.93))!important;
  }
  .dep_asses{
    background: linear-gradient(rgb(238,241,245), rgba(207,205,204,0.93))!important;
  }
  .audit_asses{
    background: linear-gradient(rgb(27,32,37), rgba(10, 10, 10, 0.93))!important;
  }
  .auditc_asses{
    background: linear-gradient(rgb(247,246,247), rgb(239, 243, 243))!important;
  }
  .panic_asses{
    background: linear-gradient(rgb(118,138,147), rgba(221, 230, 230, 0.93))!important;
  }
  .ptsd_asses{
    background: linear-gradient(rgb(87,93,108), rgba(221, 230, 230, 0.93))!important;
  }
  .phq_asses{
    background: linear-gradient( rgb(106,63,41), rgba(221, 230, 230, 0.93))!important;
  }
  .welcome_note_asses h2 {

      font-size: 40px;
      font-weight: bolder;
  }
  .about_img_asses{
    display: flex;
    width: 100%;
    height: 450px;
    /* background: linear-gradient(rgb(240,206,200), rgba(233, 188, 227, 0.95)); */
    background: linear-gradient(rgb(237,203,195), rgba(184, 143, 143, 0.95));


  }
  .inner_img_asses{
    position: relative;
    left: 10%;
    height:420px;
    width: 620px;
    border-radius: 0 0 50% 50%;
    background: #26325A;
    padding: 10px;
   
  }
  .inner_img_asses h2 {
    color: #fff;
    position: relative;
    top:17%;
    text-align: center;
  }
  .inner_img_asses p {
      padding: 5px 5px 8px 5px;
      position: relative;
      color: #fff;
      font-size: 17px;
      top:17%;

  }
  .about_asses{
    display: flex;
    width: auto;
    height: 400px;
  }
  .about_asses_inner{
      max-width:400px;
      position: relative;
      left: 15%;
      top: 10%;
  }
  .about_asses_inner h2{
    position: relative;
    top: 5%;
    color: rgb(7, 3, 37);
    font-size: 35px;
  }
  .about_asses_inner p {
    position: relative;
    top: 5%;
    color: rgb(7, 3, 37);
    font-size: 17px;
  }
  .about_asses_shadow{
      max-width: 440px;
      max-height: 300px;
      padding: 30px;
      position: relative;
      left:25%;
      top: 14%;
      box-shadow: 0 11px 50px -18px rgb(77 101 9)
      }
      .about_asses_shadow h2 {
        padding: 50px;
        color: #e72121;
        font-size: 40px;
        font-weight: 900;
        position: relative;
        top:5%;
      }
      .about_asses_shadow p{
        position: relative;
        bottom: 16%;
        color: #ee1c46;
        text-align: center;
      }
      .testimonial_container_asses{
        position: relative;
        display: flex;
        flex-direction: row;
        margin: 45px 0;
        padding: 25px 0;
      }
     
      .fixed-nav-bar {
        position: fixed;
        align-items: flex-end;
        justify-content: flex-end;
        top: 0;
        left: 0;
        z-index: 9999;
        width: 100%;
        height: 60px;
        background-color: white;
        box-shadow: 0px 0px 10px gray;
        display: flex;
        line-height: 50px;
      }
      .fixed-nav-bar-scrolled {
      position: fixed;
      z-index: 9999;
      background-color: #fff!important;
      color: #2e2bce!important;
      width: 100%;
      box-shadow: 0px 4px 8px rgba(10, 28, 64, 0.1), 0px 4px 4px rgba(10, 28, 64, 0.1);
      transition: box-shadow 0.3s ease-in-out 0s;
  }
  .nav_asses_button{
    width: 100px;
    height: 40px;
    background-color: #2076ee;
    margin-bottom: 10px;
    margin-right: 20px;
    padding: 0px;
    line-height: 2px;
    border-radius: 10px;
    color: #ffff;
    font-weight: bold;

  }
  .nav_asses_button:hover{
    color: #fff; 
    background-color: lightseagreen;

  }
      .fixed-nav-bar 
        nav img {
            float: left !important;
      }
      
      .fixed-nav-bar .assess_brand {
        flex: 1;
        align-self: flex-start;
        height: 50px;
        margin-left: 10px;
      }
      
      .fixed-nav-bar  .asses_link {
        /* flex: 1;
        max-width: max-content; */
        margin-right: 40px;
        font-weight: bold;
      }
      .asses_begin_bottom{
          width: auto;
          height: 420px;
          display: flex;
          flex-direction: row;
          /* background: linear-gradient(rgb(240,206,200), rgba(233, 188, 227, 0.95)); */
          background: linear-gradient(rgb(237,203,195), rgba(238, 238, 226, 0.95));


      }
      .asses_begin_bottom_para{
        max-width: 500px;
        position: relative;
        left: 30%;
        top:15%;
        font-size: 20px;
      }
      .asses_begin_bottom_img{
          position: relative;
          left: 18%;
      }
      .begin_bottom_asses{
        position: relative;
        left: 60%;
        padding: 10px;
        min-width: 120px;
        height: 50px;
        color: #fff;
        background-color: #2f2ce4;
        font-weight: bold;
        border-radius: 10px;
        top: 25%;

      }
      .typeform_text_notice{
          position: relative;
          max-width: 400px;
          left:-50%
      }
@media (max-width:450px){
    .typeform-app{
        position: relative;
        bottom: 45px;
    }
    .typeform-ques__buttons{
        display: flex!important;
        justify-content: flex-start!important;
        left: 0px;
        bottom: 0px;
    }
    .typeform-welcome-screen {
      text-align: center;
      
    }
    .typeform-question-text{
        position: relative;
        left: 8%;
        margin-bottom: 20px;
        font-size: 15px;
        color: black;
        font-weight: bold;
        max-width: 85%;
    
    }
    .typeform-welcome-text {
        position: relative;
        text-align: center;
    }
    .typeform-button{
        padding: 10px;
        color: #fff;
        background-color: lightskyblue;
        width: 50%;
        font-size: 15px;
        position: absolute;
        /* right: -20%; */
        margin-top: 190px;
        left: -20%;
    }
    .typeform-answer-section{
        position: relative;
        left: 8%;
        min-width: 60%;
        height: 2%;
        }
    .typeform-question-button   {
        min-width: 100%;
        margin: 4px 0;
        background: #fff;
        border: 1.5px solid #91AEDC;
        border-radius: 3px;
        font-family: Noto Sans,sans-serif;
        font-size: 15.5px;
        padding: 8px 39px 8px 18px;
        text-align: left;
        cursor: pointer;
        outline: none;
        text-align: center;
        background-color: #E5ECF7;
        color: #0445AF;
    
    }
    .typeform-ok-button{
        position: relative;
        left: 8%;
    }
    .typeform-submit-section{
        margin-left:50px;
    }
    .typeform-question-submit {
        position: relative;
        font-weight: bold;
        padding: 10px 25px;
        margin-left: -25px;
        border-radius: 5px;
        margin-top: 10px;
        color: #fff;
        background-color: #0445AF;
    }
    .welcome_note_asses{
        position: relative;
        top: 0;
        left: 3%!important;
        max-width: 95%!important;
        font-size: 15px;

    }
    .fixed-nav-bar{
        min-width: 45%;
        max-width: 100%;

    }
    .fixed-nav-bar-scrolled{
        min-width: 45%;
        max-width: 100%;
    }
    .welcome_asses{
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        max-height: 120vh!important;
        padding: 8px;
        margin-left: 2px;
        margin-right: 2px;
        text-align: center;
    }
    .welcome_asses img{
        position: relative;
        left: -16%;
        max-height: 60%;
    }
    .asses_container{
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .start_asses{
        position: relative;
        top: 1%!important;
        max-width: 20vh!important;
        left: 28%!important;
    }
    .about_asses{
        display: flex;
        flex-direction: column;
        min-height: 80vh;
        margin-top: 20px;
    }
    .about_img_inside{
        position: relative;
        left: -15%;
    }
    .about_asses_inner{
        position: relative;
        top: 0;
        min-width: 60%;
        max-width: 100%;
        left: 0; 
        text-align: center;
        margin-left: 2px;
        margin-right: 2px;
        padding: 8px;

    }
    .about_asses_shadow{
        position: relative;
        top: 0;
        margin-top: 30px;
        left:6.5%;
        max-width: 86%;
        min-height: 40%;
    }
    .about_asses_shadow h2{
        padding: 30px;
        position: relative;
        top: 10%;
    }
    .about_asses_shadow p{
        position: relative;
        bottom: 8%;
    }
    .about_img_asses{
        position: relative;
       display: flex;
       flex-direction: column;
       min-height: 100vh;
       top: 0;
       margin-top: -30px;
    }
    .inner_img_asses{
        position: relative;
        left:0;
        max-width: 100%!important;
        min-height: 53%!important;
        border-radius: 0;
        top: 2%;
        background-color: #9ab1e2;
    }
    .inner_img_asses p{
        color: #333232;

    }
    .inner_img_asses h2 {
        color: black;
        font-weight: bold;
    }
    .about_img_inside{
        position: relative;
        top: 0;
        margin-top: 10px;
    }
    .asses_carousel_item{
        max-width: 85%!important;
    }
    .asses_container_carousel{
        position: relative;
        display: flex;
        flex-direction: column;
       top: 18%!important;
    }
    .asses_testimonial{
        max-width: 100%!important;
    }
    .asses_begin_bottom{
        position: relative;
        display: flex;
        flex-direction: column; 
        min-height: 100vh;
        max-height: 120vh;
    }
    .asses_begin_bottom_para{
        position: relative;
        top:0;
        left: 0;
        margin-left: 2px;
        margin-right: 2px;
        padding: 8px;
        min-width: 60%;
        max-width: 100%;
        text-align: center;
    }
    .asses_begin_bottom_img{
        position: relative;
        top:-10%;
        left: -8%;
        max-height: 50vh;
        max-width: 30vh;
    }
    .asses_begin_bottom_img img{
        max-height: 50vh;
        max-width: 55vh;
    }
    .begin_bottom_asses{
        position: relative;
        top: 170%;
        left: 30%;
    }
    .asses_footer{
        max-width: 100%!important;
    }
    .asses_section_header{
        max-width: 100%!important; 
    }
    .gad_button{
        position: relative;
        top: 200%;
    }
    .audit_button{
        position: relative;
        top: 200%;
    }
    .panic_button{
        position: relative;
        top: 130%;
    }
}
@media (max-width:768px) and (min-width:450px) {
    .asses_container{
        overflow-y: auto;
        max-width: 100vh!important;
        overflow-x: hidden;
    }
    .welcome_asses {
        width: 100%!important;
        position: relative;
        max-height: 36vh;
    }
    .welcome_asses img{
        position: relative;
        left: 2%;
        min-height: 89%;
        max-height:100%;
        max-width: 35%;
    }
    .welcome_note_asses{
        position: relative;
        top: 3%;
        left: 10%;
        min-width:55%!important;
    }
    .start_asses{
        position: relative;
        top:75%;
        left: -35%;
        min-width: 20vh;
    }
    .about_img_asses{
        width: 100%!important;
    }
    .about_img_inside img{
        max-height: 100%;
        max-width: 100%;
    }
    .about_asses{
        width: 100%!important; 
    }
    .about_asses_inner{
        position: relative;
        left: 2%;
        max-width: 40vh;
    }
    .about_asses_shadow{
        position: relative;
        left: 4%;
        max-width: 30vh;
    }
    .about_asses_shadow h2{
        padding: 10px;
        position: relative;
        top: 10%;
        left: 5%;
    }
   
    .about_asses_shadow p{
        text-align: center;
        font-size: 20px;
       position: relative;
       top: 10%;
       margin-bottom: 50px;
    }
    .inner_img_asses{
        position: relative;
        left: 0;
        max-width: 45vh;
        padding: 4px;
        max-height: 60vh;
    }
    .asses_container_carousel{
        width: 100%!important;
        display: flex;
        flex-direction: column;
    }
    .asses_carousel_item{
        min-width: 100%!important;
        position: relative;
    }
    .asses_begin_bottom{
        width: 100%!important;
       
    }
    .asses_begin_bottom_para{
        position: relative;
        left: 10%;
    }
    .begin_bottom_asses{
        position: relative;
        left: 35%;
    }
   .asses_begin_bottom_img{
       position: relative;
       left: 2%;
   }
    .asses_testimonial{
        width: 100%!important;
        position: relative;
        left: 1%;
    }
    .asses_begin_bottom_img img{
        max-height: 100%;
        max-width: 100%;
    }
    .asses_faq{
        width: 100%!important;
    }
    .asses_footer{
        width: 100%!important;
    }
}

/* form builder code */


.form_builder{
    width: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: row;
}
.form_toolbar{
    width: 40vh;
    min-height: 92%;
    margin-top: 5%;
    border: 1px solid grey;
    margin-left: 20px;
    background-color: #215253;
    /* background-color: rgb(37, 36, 36); */
}
.select_field{
    text-align: center;
    color: #fff;
    border-bottom: 2px solid #fff;
    padding: 20px 10px 20px 10px;
    font-size: 20px;
    font-weight: bold;
}
.form_build_preview{
    min-width: 100vh;
    min-height: 90%;
    max-height: 92%;
    border: 1px solid black;
    margin-top: 2%;
    position: relative;
    left: 70px !important;
    overflow-y: scroll;
}
.create_form{
    margin: '0 auto';
}
.form_build_preview ul li{
    list-style: none;
}
.form_item{
    margin: 8% 5%;
    border: 1px solid black;
    padding: 5%;
    width: 85%;
    cursor: pointer;
    align-self: center;
    display: flex;
    flex-direction: row;
    background-color: #ffff;
    border-radius: 10px;
    padding-left: 15% !important;
    text-align: center;
   
    
}
.edit_form{
    position: relative;
    left: 570px !important; 
    border: 1px solid grey;
    width: 40vh;
    min-height: 90%;
    margin-top: 5%;
}
/* .form_inputs{
    display: flex;
    flex-direction: row;
    margin: 30px;
} */
/* .form_inner_question{
    display: flex;
    flex-direction: row;
    margin: 10px;
} */
.form_input_text{
    position: absolute;
    top:10%;
    left:8%;
    width: 50%;
    height: 5%;
    padding: 2px 6px;
    border-radius: 4px;
}
.prev_container{
    /* border: 1px solid grey; */
    /* width: 60vh !important; */
    cursor: pointer !important;
    /* margin-right: 30vh !important; */
}
.prev_container p input{
    cursor: pointer !important;

}
.edit_button_view{
    float: right;
}
.form_name_container{
    margin: 0 auto;
    width: 107vh;
    display: flex;
    flex-direction: column;
    background-color: #215253;
    /* background-color: rgb(37, 36, 36); */
    height: 240px;
}
.form_name_container label{
        float: left;
        margin-top: 23px;
        margin-left: 40px;
        color:#fff;
        font-weight: bold;
        font-size: 16px;

}
.inputs_form{
    display: flex;
    flex-direction: row;
}
.form_name_input{
    width: 70%;
    height: 40px;
    margin: 0 auto !important;
    margin-top: 15px !important;
    padding-left: 10px;
    border-radius: 6px;
}
.form_description_input{
    width: 70%;
    height: 70px;
    padding-top: 8px;
    padding-left: 10px;
    border-radius: 6px;
    margin: 0 auto !important;
    margin-top: -30px !important;
    margin-right: 68px !important;
}
.form_file_input{
    width: 70%;
    height: 30px;
    color:#fff;
    border-radius: 6px;
    margin: 0 auto !important;
    margin-top: -30px !important;
    margin-right: 68px !important;
    border: 1px solid #fff; 
}
.inner_input_form{
    border: none ;
    outline: none;
    border-bottom: 1px solid rgb(42, 42, 44) !important;
    margin-left: 20px;
    width: 50vh;
}
.inner_textarea_form{
    margin-left: 20px;
    width: 50vh;
    padding-top: 10px;
    padding-left: 10px;
}
.inner_input_form input:focus{
    outline: none !important;
    border: 1px solid #fff !important;

}
.form_question{
    border-bottom: 1px solid rgb(42, 42, 44) !important;
    width: 50vh;
    margin-top: 10px;
    /* float: right; */
}
.edit_section{
    float: right;
    margin-top: 10px;
    width: 40vh;
    margin-right: 25px;
}
.preview_section{
    float: left;
    margin-bottom: -20px;
    margin-top: 18px;
    width: 90vh;
}

.delete_input_form{
    display: none;
}
.prev_container :hover .delete_input_form{
   float: right !important;
   position: relative !important;
   display: inline !important; 
   left: -40vh !important;
   top:-2vh !important;
}
.edit_input_placeholder{
    width: 42vh;
    padding: 8px;
    margin-bottom: 25px;
    border-radius: 3px;
    float: right;
    position: relative;
    top:-5.5vh;
    left:10vh;

}
.edit_input_value{
    width: 42vh;
    padding: 8px;
    margin-bottom: 3px;
    border-radius: 3px;
    float: right;
    position: relative;
    top:.5vh;
    left:52vh;
}
.edit_input_value_textarea{
    width: 42vh;
    padding: 8px;
    margin-bottom: 3px;
    border-radius: 3px;
    float: right;
    position: relative;
    top:-5vh;
    left:52vh; 
}
.edit_input_value_max{
    width: 42vh;
    padding: 8px;
    margin-bottom: 3px;
    border-radius: 3px;
    float: right;
    position: relative;
    top:1vh;
    left:10vh; 
}
.input_dropdown_form{
    float: right;
    /* margin-top: -4px; */
    display: 'column';
    column-count: 2;
    margin-left: -8px;
    position: relative;
    top:-4vh;
    left:51vh;
}
.dropdown_el{
    padding-left:4px ;
    border-radius: 2px;
}
.input_dropdown_form_radio{
    float: right;
    /* margin-top: -4px; */
    display: 'column';
    column-count: 2;
    margin-left: -8px;
    position: relative;
    top:-10vh;
    left:51vh;  
}
.up_row{
    position: relative;
    top:-14vh; 
}
.less_up{
    position: relative;
    top:-12vh;  
}
.range_scale{
    position: relative;
    top:-3vh;  
}
.inside_dropdown_input{
    margin-bottom: 10px;

}
.dropdown_btn_form{
    display: flex;
    flex-direction: column;
    position: relative;
    left: 22vh;
    top: -4.2vh;
}
.select_form_input{
    margin-left: 20px;
    padding: 10px;
}
.radio_input_form{
    max-width: 60vh;
    margin-left: 15px;
}
.option_check{
    margin-bottom: 8px;
}
.form_range_input{
    max-width: 60vh;
    margin-left: 15px;
}
.btn_form_range0{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(224, 226, 88);
}
.btn_form_range1{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(237, 234, 12);
}
.btn_form_range2{
    /* border: .5px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(251, 247, 5);
}
.btn_form_range3{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(135, 224, 107);
}
.btn_form_range4{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(95, 198, 21);
}
.btn_form_range5{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(50, 163, 6);
}
.btn_form_range6{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(19, 116, 37);
}
.btn_form_range7{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(233, 136, 57);
}
.btn_form_range8{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(210, 52, 52);
}
.btn_form_range9{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: red;
}
.build_form{
    width: 100vh;
    height: 40px;
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    color: #fff;
    background-color: #215253;
}
.final_prev_form{
    position: absolute;
    width: 100vh;
    top:10vh;
    left: 5vh;
    z-index: 999;
    overflow-y: scroll;
    background-color: #ffff;
    min-height: 50vh;
    height: 100vh;
    box-shadow: -3px 0px 20px 15px rgba(207,192,192,0.91);
    -webkit-box-shadow: -3px 0px 20px 15px rgba(207,192,192,0.91);
    -moz-box-shadow: -3px 0px 20px 15px rgba(207,192,192,0.91);
}
.save_btn_form{
    width: 10vh;
    background-color: #fff;
    color: #108075;
    font-weight: bold;
    font-size: 16px;
    border-radius: 8px;
    position: relative;
    left: 90vh;
    top:1vh;
}
/* .dis_btn{
    color:#fff !important;
    background-color: #67676b;
} */
.icons_toolbar{
    position: absolute;
    left: 32vh !important;
    color: #215253;
}
.inline_preview{
    position: relative;
    left: 5vh;
    margin-bottom: 5px;
}
.input_inline_prev{
    margin-bottom: 10px;
    margin-left: 30px;
    padding:5px 15px;
}
.answer_input_inline_prev{
    margin-left: 30px; 
}
.radio_inline_prev{
    margin-left: 12px;
    /* border: 1px solid #215253;
    padding: 5px 10px;
    background-color: #215253;
    color:#fff */
}
.inline_radio{
   margin-left: 10px;
   display: flex;
   flex-direction: row;
   
}
.date_inline_prev{
    margin-left: 20px;
    border: 1px solid black;
    padding: 5px 10px;
    width: 15vh;
}
.answer_section{
    width: 100vh;
    margin: 0 auto;
    margin-top: 10vh;
    height: auto;
    padding: 30px;
    /* margin-left: 35vh; */
    /* overflow-y: scroll; */
    border: 1px solid #215253;
    background-color: #215253;
    color:#ffff;
    padding-bottom: 10vh;
}
.answer_types{
    margin-left: 1vh;
}
.input_inline_prev_ans{
    width: 90vh;
    margin-bottom: 3vh;
    height: 40px;
    padding-left: 18px;
    margin-left: 20px;
}
.form_title{
    text-align: center;
    text-decoration: underline;
    color: #fff;
    font-weight: bold;
}
.input_inline_prev_drop{
    margin-left: 24px;
    padding: 10px;
    margin-bottom: 3vh;
}
.check_box_ans{
    display: flex;
    flex-direction: row !important;
    border: 1px solid #fff;
    margin: 1vh;
    margin-bottom: 3vh;
    background-color: #fff;
    padding-left: 7px  !important;
    padding-top: 5px !important;
    height: 45px;
}
.inline_radio_ans{
    display: flex;
    flex-direction: row !important; 
    margin-left: 25px;
    border-radius: 5px;
   
}
.input_inline_txt_prev_ans{
    padding: 10px;
    margin-bottom: 3vh; 
    height: 80px;
    width: 90vh;
    padding-left: 18px;
    margin-left: 25px;
}
.MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
}
.date_inline_prev_ans{
    border:none;
    margin-left: 30px;
    margin-bottom: 3vh;
    }
.form_range_input_ans{
    /* background-color: #fff; */
    width: 85vh;
    margin-left: 0px;
    /* color: #000; */
}
.btn_grp_ans{
    padding:10px ;
    text-align: center;
}
.active_btn{
    border:3px solid #fff;
    /* color:#2076ee !important; */
    /* background-color: #fff; */
    width: 50px;
    height: 50px;
    font-size: 20px;
}
.err_msg{
    text-align: center;
    font-weight: bold;
    color:#F50157;
    margin-left: 35vh;
    position: relative;
    top:-2vh;
}
.date_pic_ans{
    padding: 6px 3px;
    font-size: 18px;
    text-align: center;
}
.submit_btn_ans{
    float: right;
    width: 15vh;
    padding: 6px;
    margin-top: 20px;
    border-radius: 5px;
    font-weight: bold;
}
.welcome_form{
    width:100vh;
    margin: 0 auto;
    margin-top: 20vh;
}
.form_wel_title{
    width: 90vh;
    text-align: center !important;
    font-size: 25px;
    margin: 0 auto;
}
.form_wel_description{
   width: 90vh; 
   margin: 0 auto;
   margin-top: 20px;

}
.wel_btn{
    border-radius: 3px;
    padding: 10px;
    position: relative;
    margin-top: 3vh;
    left: 40vh;
}
.answer_section_form{
    width: auto;
    height: 100vh;
    padding: 30px;
    background-color: #215253;
    color:#ffff;
}
.answer_types_form{
    margin-top: 20vh;
    margin-left: 10vh;
}
.ques_inx{
    font-size: 22px;
}
.input_inline__ans{
    width: 90vh;
    margin-bottom: 3vh;
    height: 40px;
    padding-left: 18px;
    margin-left: 16vh; 
}
.next_div{
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 10vh;
    right: 10vh;
    cursor: pointer;
}
.exp_less{
    border: 1px solid #fff;
    margin: 3px;
    font-size: 26px;
}
.err_msg_{
    text-align: center;
    font-weight: bold;
    color:#F50157;
    position: relative;
    top:6vh;
    left: -60vh;
}
.input_inline__drop{
    margin-left: 18vh;
    padding: 10px;
    margin-bottom: 3vh;
    width: 35vh;
    margin-top: 3vh;
}
.inline_radio__ans{
    display: flex; 
    width: 35vh;
    margin-left: 18vh;
    border-radius: 5px;
}
.check_span{
    position: relative;
    left: 25vh;
}
.date_inline__ans{
    margin-left: 18vh;
    /* border: 1px solid black; */
    padding: 5px 10px;
    width: 15vh; 
}
.input_inline_txt__ans{
    padding: 10px;
    margin-bottom: 3vh; 
    height: 80px;
    width: 90vh;
    padding-left: 18px;
    margin-left: 18vh; 
}
.form_range__ans{
    position: relative;
    left:-35vh;
}
.btnform_range0{
    width: 50px;
    height: 50px;
    background-color: rgb(224, 226, 88);
}
.btnform_range1{
    width: 50px;
    height: 50px;
    background-color: rgb(237, 234, 12); 
}
.btnform_range2{
    width: 50px;
    height: 50px;
    background-color: rgb(251, 247, 5); 
}
.btnform_range3{
    width: 50px;
    height: 50px;
    background-color: rgb(135, 224, 107); 
}
.btnform_range4{
    width: 50px;
    height: 50px;
    background-color: rgb(95, 198, 21); 
}
.btnform_range5{
    width: 50px;
    height: 50px;
    background-color: rgb(50, 163, 6); 
}
span.rep-back {
    font-size: 30px;
    padding: 5px;
}
.ls-h {
    padding-left: 20px;
}
a.ls-p {
    text-decoration: none;
    font-size: 25px;
    padding-left: 15px;
    color: #215253;
}

.btnform_range6{
    width: 50px;
    height: 50px;
    background-color: rgb(19, 116, 37); 
}
.btnform_range7{
    width: 50px;
    height: 50px;
    background-color: rgb(233, 136, 57); 
}
.btnform_range8{
    width: 50px;
    height: 50px;
    background-color: rgb(210, 52, 52);
}
.btnform_range9{
    width: 50px;
    height: 50px;
    background-color: red;
}
.typeform_progress_bar_{
height: 33px;   
background-color: #108075;
margin-top: 2px;
padding-top: 3px;
}
.submit_ans_{
    padding: 5px 10px;
    border-radius: 10px;
    color:#000;
    font-weight: bold;
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
   .form_builder{
       display: flex;
       flex-direction: column;
       width: 100vh;
       height: auto;
       overflow-y: scroll;
   }
   .form_toolbar{
       width: 70vh;
       margin: 0 auto ;
       /* margin-right: 75px !important; */
   }
   .form_item{
       width: 55vh;
       font-size: 28px;
       padding: 15px;
       margin-left: 60px;
       
   }
   .icons_toolbar{
       margin-left: 305px;
   }

   .form_build_preview {
       max-width: 40vh !important;
       left: 0!important;
       position: relative;
   }
   .form_name_container{
       max-width: 100vh;
       margin-left: -10px;
   }
   .prev_container{
       max-width: 40vh;
   }
   .preview_section{
      max-width: 40vh;
   }
   .select_field{
       font-size: 28px;
       font-weight: bold;
   }
   .form_description_input{
       margin-left: 4vh;
       position: relative;
       left: -3vh;
   }
   .form_file_input{
    position: relative;
    left: -3vh;
   }
   .save_btn_form{
    position: relative;
    left: 88vh;
    top:1vh;
}
.form_question{
    width: 45vh;
    font-size: 20px;
}
.inner_input_form{
    width: 45vh;
    font-size: 20px;
}
.inner_textarea_form{
    width: 40vh;
}
.edit_input_placeholder{
    width: 40vh;
    float: right;
    position: relative;
    top:-5.5vh;
    left:50vh;
}
.edit_input_value_textarea{
    width: 40vh;
    position: relative;
    top:-6.5vh;
    left:50vh;
}
.edit_input_value{
    position: relative;
    top:-7vh;
    left:50vh; 
    width: 40vh;
}

.input_dropdown_form{
    position: relative;
    top:-6vh;
    left: 39vh;
}
.input_dropdown_form_radio{
    position: relative;
    top:-6vh;
    left: 39vh;
}
.delete_input_form{
    display: inline;
    position: relative;
    left: 40vh;
}
.range_scale{
    position: relative;
    top:1vh;  
}
.dropdown_btn_form{
    position: relative;
    left: 20vh;
    top: -2.2vh;
}
.answer_section_form{
    width:75vh;
    height: 100vh;
    overflow-y: hidden !important;
}
.next_div{
    position: absolute;
    right:5vh;
    bottom:5vh;
}
.answer_types_form{
   margin-left: 0;
}
.input_inline__ans{
    width: 65vh;
    margin-left: 4vh;
}
.input_inline__drop{
    margin-left: 6vh;
}
.welcome_form{
    width: 50vh;
    margin: 0 auto;
    margin-top: 20vh;
    
}
.form_wel_title{
    width: 50vh;
}
.form_wel_description{
    width: 60vh;
    text-align: center;
    margin-left: -25px;
}
.wel_btn{
    left: 20vh;
}

.inline_radio__ans{
    margin-left:6vh;
}
.date_inline__ans{
    margin-left: 5vh;
}
.input_inline_txt__ans{
    margin-left: 5vh;
    width: 60vh;
}
.err_msg_{
   left: 26vh;
   top:0;
   position: relative;
}
.form_range__ans{
    position: relative;
    left: 0vh;
}
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        .answer_section_form{
            width:46vh;
            /* max-width: 50vh; */
            height: 100vh;
            overflow-y: hidden !important;
        }
        .next_div{
            position: absolute;
            right:5vh;
            bottom:5vh;
        }
        .answer_types_form{
           margin-left: 0;
        }
        .input_inline__ans{
            width: 35vh;
            margin-left: 4vh;
        }
        .input_inline__drop{
            margin-left: 6vh;
        }
        .welcome_form{
            width: 30vh;
            margin: 0 auto;
            margin-top: 20vh;
            
        }
        .form_wel_title{
            width: 30vh;
        }
        .form_wel_description{
            width: 40vh;
            text-align: center;
            margin-left: -35px;
        }
        .wel_btn{
            left: 10vh;
        }
        .input_inline__drop{
            width: 33vh;
        }
        .inline_radio__ans{
            margin-left:6vh;
            width: 30vh;
        }
        .check_box_ans{
            max-width: 30vh;
        }
        .check_span{
            margin-left: -15vh;
        }
        .date_inline__ans{
            margin-left: 5vh;
        }
        .input_inline_txt__ans{
            margin-left: 5vh;
            width: 35vh;
        }
        .form_range__ans{
            position: relative;
            left: -1vh;
            font-size: 10px;
        }
        .btnform_range0{
            width: 25px;
            height: 25px;
            background-color: rgb(224, 226, 88);
        }
        .btnform_range1{
            width: 25px;
            height: 25px;
            background-color: rgb(237, 234, 12); 
        }
        .btnform_range2{
            width: 25px;
            height: 25px;
            background-color: rgb(251, 247, 5); 
        }
        .btnform_range3{
            width: 25px;
            height: 25px;
            background-color: rgb(135, 224, 107); 
        }
        .btnform_range4{
            width: 25px;
            height: 25px;
            background-color: rgb(95, 198, 21); 
        }
        .btnform_range5{
            width: 25px;
            height: 25px;
            background-color: rgb(50, 163, 6); 
        }
        .btnform_range6{
            width: 25px;
            height: 25px;
            background-color: rgb(19, 116, 37); 
        }
        .btnform_range7{
            width: 25px;
            height: 25px;
            background-color: rgb(233, 136, 57); 
        }
        .btnform_range8{
            width: 25px;
            height: 25px;
            background-color: rgb(210, 52, 52);
        }
        .btnform_range9{
            width: 25px;
            height: 25px;
            background-color: red;
        }
        .active_btn{
            border:2px solid #fff;
            /* color:#2076ee !important; */
            /* background-color: #fff; */
            width: 30px;
            height: 30px;
            font-size: 10px;
        }
        .err_msg_{
            left: 12vh;
            top:-10px;
            position: relative;
         }
        }